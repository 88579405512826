<template>
  <div class="containerx">
    <div class="table-box">
      <el-table border :stripe="true" v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark"
      style="width: 98%" >
        <el-table-column label="序号" type="index" width="100">
        </el-table-column>
        <el-table-column prop="username" label="操作人员" >
        </el-table-column>
        <el-table-column prop="operationContent" label="操作内容"  show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createTime" label="操作时间" show-overflow-tooltip>
        </el-table-column>
      </el-table>
    </div>

    <div class="page-box">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="queryForm.page" :page-size="queryForm.size" layout="prev, pager, next, jumper"
        :total="pageTotal">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: 'index1',
  props: {
    // 项目id
    projectId: {
      type: Number,
      default: null
    },
    projects: {
      type: Number,
      default: null
    },
    // 层级id
    levelId: {
      type: Number,
      default: null
    }
  },
  components: {
  },
  data() {
    return {
      loading: false,
      queryForm: {
        page: 1,
        size: 10,
      },
      pageTotal: 0,
      tableData: [],

    }
  },
  methods: {
    //全选/不选
    selectAll(res) {

    },
    changeEquipmentId(res, index) {
    },

  // 用户日志列表
  getFacility() {
    this.loading = true
    this.$newPost('/userLog/getUserLogList', this.queryForm).then(res => {
      this.loading = false
      this.tableData = res.data.records
      this.pageTotal = res.data.total
    })
  },
  handleCurrentChange(e) {
    this.queryForm.page = e
    this.getFacility()
  },
  handleSizeChange(e) {
    this.queryForm.pageSize = e
    this.getFacility()
  },
  inputSn() {
    this.getFacility()
  },
  clearSn() {
    this.equipmentSn = null
    this.getFacility()
  },
  searchEquipment() {
    this.equipmentSn = null
    this.getFacility()
  }
},
mounted() {
  this.getFacility()
},
}
</script>

<style lang="scss" scoped>
.containerx {
  width: 100%;

  .search-box {
    display: flex;
    width: 100%;
    min-height: 8rem;
    align-items: center;
  }

  .table-box {
  margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .page-box {
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }

}
</style>
